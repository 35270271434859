import { PESOS_EXCHANGE_KEY } from '@/services/modules/exchangeRate'
import i18n from '@/setup/i18n'
import { esMx, languages } from '@/utils/language'
import { computed } from '@vue/composition-api'
import { useExchangeRate } from './exchangeRate'
import { CurrencyViewModel } from '@/services/modules/currency'

const { data } = useExchangeRate({ exchangeKey: PESOS_EXCHANGE_KEY })

export const pesosExchangeRateAmount = computed(() => data.value[0]?.amount)

export const isDisplayMxCurrency = computed(() =>
  Boolean(i18n.locale === esMx.locale && pesosExchangeRateAmount.value)
)

export function convertFromUsdToMxn(value: number) {
  return Math.ceil(value * pesosExchangeRateAmount.value)
}

export function convertFromMxnToUsd(value: number) {
  return Math.ceil(value / pesosExchangeRateAmount.value)
}

export function getLocalizedPrice(value: number | undefined) {
  return isDisplayMxCurrency.value && value ? convertFromUsdToMxn(value) : value
}

export function getUsdPrice(value: number | undefined) {
  return isDisplayMxCurrency.value && value ? convertFromMxnToUsd(value) : value
}

export interface UseCurrencyOptions {
  value: number | string
  locale?: string
  currency?: CurrencyViewModel
}

export function useCurrency({
  value,
  locale = i18n.locale,
  currency,
}: UseCurrencyOptions) {
  return computed(() => {
    const computedLocale = currency
      ? Object.values(languages).find(
          (language) => language.currencyCode === currency?.code
        )?.locale ?? locale
      : locale
    const shouldConvertToMexicanPeso =
      !currency &&
      Boolean(locale === esMx.locale && pesosExchangeRateAmount.value)
    let amount = typeof value === 'string' ? Number.parseFloat(value) : value
    const currencySuffix = shouldConvertToMexicanPeso
      ? 'MXN'
      : currency?.code || 'USD'

    if (shouldConvertToMexicanPeso) {
      amount = convertFromUsdToMxn(amount)
    }

    return `$${amount.toLocaleString(computedLocale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })} ${currencySuffix}`
  })
}
