import { AreaModel, areaService } from '@/services/modules/area'
import { currentLanguage } from '@/utils/language'
import { computed, ComputedRef, isRef } from '@vue/composition-api'
import { useService } from './service'

export function useAreas(model?: ComputedRef<AreaModel> | AreaModel) {
  const request = computed<AreaModel>(() => ({
    languageId: currentLanguage.value.guid,
    ...(isRef(model) ? model.value : model),
  }))
  return useService(request, () => areaService.get(request.value))
}
