import {
  ExchangeModel,
  exchangeRateService,
} from '@/services/modules/exchangeRate'
import { computed } from '@vue/composition-api'
import { useService } from './service'

export function useExchangeRate(model?: ExchangeModel) {
  const request = computed<ExchangeModel>(() => ({
    ...model,
  }))

  return useService(request, () => exchangeRateService.get(request.value))
}
