





































































































































































































import PropertyListFilters from '@/components/PropertyListFilters.vue'
import { useProperties } from '@/composition/properties'
import {
  PropertyCategoryId,
  PropertyModel,
  PropertyViewModel,
} from '@/services/modules/property'
import { GuidIdTableType } from '@/services/utils'
import { getUsdPrice, useCurrency } from '@/composition/currency'
import { getPropertyRouteTo } from '@/utils/property'
import i18n from '@/setup/i18n'
import {
  VueGoodTableColumn,
  VueGoodTablePageChangeEvent,
  VueGoodTablePerPageChangeEvent,
} from '@/setup/vue-good-table'
import router, { currentRoute } from '@/router'
import { computed, defineComponent, ref, watch } from '@vue/composition-api'

const statuses = ['all', 'active', 'inactive'] as const
type Status = typeof statuses[number]

const categories = ['all', 'sales', 'rentals'] as const
type Category = typeof categories[number]

const boosts = ['recommended', 'featured'] as const
type Boost = typeof boosts[number]

export default defineComponent({
  name: 'PropertyList',

  components: {
    PropertyListFilters,
  },

  setup() {
    const pageNumber = computed(() =>
      currentRoute.value &&
      currentRoute.value.query.pageNumber &&
      typeof currentRoute.value.query.pageNumber === 'string'
        ? Number.parseInt(currentRoute.value.query.pageNumber)
        : 1
    )

    const rowsPerPage = computed(() =>
      currentRoute.value &&
      currentRoute.value.query.pageNumber &&
      typeof currentRoute.value.query.rowsPerPage === 'string'
        ? Number.parseInt(currentRoute.value.query.rowsPerPage)
        : 30
    )

    const searchBy = computed(() =>
      currentRoute.value &&
      currentRoute.value.query.searchBy &&
      typeof currentRoute.value.query.searchBy === 'string'
        ? currentRoute.value.query.searchBy
        : ''
    )

    const category = computed(() =>
      currentRoute.value &&
      currentRoute.value.query.category &&
      typeof currentRoute.value.query.category === 'string'
        ? currentRoute.value.query.category
        : ''
    )

    const status = computed(() =>
      currentRoute.value &&
      currentRoute.value.query.status &&
      typeof currentRoute.value.query.status === 'string'
        ? currentRoute.value.query.status
        : ''
    )

    const boost = computed(() => {
      let list = []

      if (currentRoute.value && currentRoute.value.query.boost) {
        const routeList = Array.isArray(currentRoute.value.query.boost)
          ? currentRoute.value.query.boost
          : [currentRoute.value.query.boost]
        for (const item of routeList) {
          if (!item) continue
          list.push(item)
        }
      }
      return list
    })

    const internalBoost = ref<Boost[]>(getBoosts(boost.value))

    watch(
      () => boost.value,
      (newValue) => {
        if (
          newValue.length === internalBoost.value.length &&
          newValue.every((v) => internalBoost.value.includes(v as Boost))
        )
          return
        internalBoost.value = getBoosts(newValue)
      }
    )

    function getBoosts(newValue: string[]) {
      let val: Boost[] = []
      const boostList = newValue as Boost[]
      for (const item of boostList) {
        if (boosts.includes(item)) {
          val.push(item)
        }
      }
      return val
    }

    watch(
      () => internalBoost.value,
      (newValue) => {
        if (
          newValue.length === boost.value.length &&
          newValue.every((v) => boost.value.includes(v))
        )
          return
        router
          .push({
            query: {
              ...currentRoute.value?.query,
              ...(pageNumber.value > 1 ? { pageNumber: '1' } : undefined),
              boost: newValue.length > 0 ? newValue : undefined,
            },
          })
          .catch(() => {})
      }
    )

    const internalSearchBy = ref(searchBy.value)

    watch(
      () => searchBy.value,
      (newValue) => {
        if (newValue === internalSearchBy.value) return
        internalSearchBy.value = newValue
      }
    )

    watch(
      () => internalSearchBy.value,
      (newValue) => {
        if (newValue === searchBy.value) return
        router
          .push({
            query: {
              ...currentRoute.value?.query,
              ...(pageNumber.value > 1 ? { pageNumber: '1' } : undefined),
              searchBy: newValue || undefined,
            },
          })
          .catch(() => {})
      }
    )

    const internalCategory = ref<Category>(getCategory(category.value))

    watch(
      () => category.value,
      (newValue) => {
        if (newValue === internalCategory.value) return
        internalCategory.value = getCategory(newValue)
      }
    )

    function getCategory(newValue: string) {
      let val: Category = 'all'
      const category = newValue as Category
      if (categories.includes(category)) {
        val = category
      }
      return val
    }

    watch(
      () => internalCategory.value,
      (newValue) => {
        if (newValue === category.value) return
        router
          .push({
            query: {
              ...currentRoute.value?.query,
              ...(pageNumber.value > 1 ? { pageNumber: '1' } : undefined),
              category: newValue,
            },
          })
          .catch(() => {})
      }
    )

    const internalStatus = ref<Status>(getStatus(status.value))

    watch(
      () => status.value,
      (newValue) => {
        if (newValue === internalStatus.value) return
        internalStatus.value = getStatus(newValue)
      }
    )

    function getStatus(newValue: string) {
      let val: Status = 'all'
      const status = newValue as Status
      if (statuses.includes(status)) {
        val = status
      }
      return val
    }

    watch(
      () => internalStatus.value,
      (newValue) => {
        if (newValue === status.value) return
        router
          .push({
            query: {
              ...currentRoute.value?.query,
              ...(pageNumber.value > 1 ? { pageNumber: '1' } : undefined),
              status: newValue,
            },
          })
          .catch(() => {})
      }
    )

    const bathroom = computed(() =>
      currentRoute.value &&
      currentRoute.value.query.bathroom &&
      typeof currentRoute.value.query.bathroom === 'string'
        ? Number.parseInt(currentRoute.value.query.bathroom)
        : undefined
    )

    const bedroom = computed(() =>
      currentRoute.value &&
      currentRoute.value.query.bedroom &&
      typeof currentRoute.value.query.bedroom === 'string'
        ? Number.parseInt(currentRoute.value.query.bedroom)
        : undefined
    )

    const minPrice = computed(() =>
      currentRoute.value &&
      currentRoute.value.query.minPrice &&
      typeof currentRoute.value.query.minPrice === 'string'
        ? Number.parseInt(currentRoute.value.query.minPrice)
        : undefined
    )

    const maxPrice = computed(() =>
      currentRoute.value &&
      currentRoute.value.query.maxPrice &&
      typeof currentRoute.value.query.maxPrice === 'string'
        ? Number.parseInt(currentRoute.value.query.maxPrice)
        : undefined
    )

    const minPrice2 = computed(() =>
      currentRoute.value &&
      currentRoute.value.query.minPrice2 &&
      typeof currentRoute.value.query.minPrice2 === 'string'
        ? Number.parseInt(currentRoute.value.query.minPrice2)
        : undefined
    )

    const maxPrice2 = computed(() =>
      currentRoute.value &&
      currentRoute.value.query.maxPrice2 &&
      typeof currentRoute.value.query.maxPrice2 === 'string'
        ? Number.parseInt(currentRoute.value.query.maxPrice2)
        : undefined
    )

    const orderBy = computed(() =>
      currentRoute.value &&
      currentRoute.value.query.orderBy &&
      typeof currentRoute.value.query.orderBy === 'string'
        ? currentRoute.value.query.orderBy
        : undefined
    )

    const propertyTypeList = computed(() => {
      let list: GuidIdTableType[] = []

      if (currentRoute.value && currentRoute.value.query.propertyTypeList) {
        const routeList = Array.isArray(
          currentRoute.value.query.propertyTypeList
        )
          ? currentRoute.value.query.propertyTypeList
          : [currentRoute.value.query.propertyTypeList]
        for (const item of routeList) {
          if (!item) continue
          list.push({ id: item })
        }
      }
      return list
    })

    const propertyAreaList = computed(() => {
      let list: GuidIdTableType[] = []

      if (currentRoute.value && currentRoute.value.query.propertyAreaList) {
        const routeList = Array.isArray(
          currentRoute.value.query.propertyAreaList
        )
          ? currentRoute.value.query.propertyAreaList
          : [currentRoute.value.query.propertyAreaList]
        for (const item of routeList) {
          if (!item) continue
          list.push({ id: item })
        }
      }
      return list
    })

    const isRecommendation = computed(
      () => internalBoost.value.includes('recommended') || undefined
    )
    const isFeatureListing = computed(
      () => internalBoost.value.includes('featured') || undefined
    )

    const model = computed<PropertyModel>(() => ({
      pageNumber: pageNumber.value,
      rowsPerPage: rowsPerPage.value,
      searchBy: searchBy.value,
      isRecommendation: isRecommendation.value,
      isFeatureListing: isFeatureListing.value,
      categoryId: getCategoryIdValue(category.value as Category),
      bathroom: bathroom.value,
      bedroom: bedroom.value,
      minPrice: getUsdPrice(minPrice.value),
      minPrice2: getUsdPrice(minPrice2.value),
      maxPrice: getUsdPrice(maxPrice.value),
      maxPrice2: getUsdPrice(maxPrice2.value),
      orderBy: orderBy.value,
      propertyTypeList: propertyTypeList.value,
      propertyAreaList: propertyAreaList.value,
      isDeleted: getIsDeletedValue(status.value as Status),
    }))

    const allLabel = computed(() => i18n.t('all') as string)

    const boostLabel = computed(() => i18n.t('boosted') as string)
    const recommendedLabel = computed(() => i18n.t('recommended') as string)
    const featuredLabel = computed(() => i18n.t('featured') as string)

    function getBoostLabel(item: Boost) {
      let label = ''
      switch (item) {
        case 'recommended':
          label = recommendedLabel.value
          break
        case 'featured':
          label = featuredLabel.value
          break
        default:
          break
      }
      return label
    }

    const categoryLabel = computed(() => i18n.t('category') as string)
    const salesLabel = computed(() => i18n.t('sales') as string)
    const rentalsLabel = computed(() => i18n.t('rentals') as string)

    function getCategoryIdValue(category: Category) {
      let value

      if (category === 'sales') {
        value = PropertyCategoryId.ForSale
      } else if (category === 'rentals') {
        value = PropertyCategoryId.Rentals
      }

      return value
    }

    function getCategoryLabel(category: Category) {
      let label = ''
      switch (category) {
        case 'all':
          label = allLabel.value
          break
        case 'sales':
          label = salesLabel.value
          break
        case 'rentals':
          label = rentalsLabel.value
          break
        default:
          break
      }
      return label
    }

    const statusLabel = computed(() => i18n.t('status') as string)
    const activeLabel = computed(() => i18n.t('active') as string)
    const inactiveLabel = computed(() => i18n.t('inactive') as string)

    function getIsDeletedValue(status: Status) {
      let value

      if (status === 'active') {
        value = false
      } else if (status === 'inactive') {
        value = true
      }

      return value
    }

    function getStatusLabel(status: Status) {
      let label = ''
      switch (status) {
        case 'all':
          label = allLabel.value
          break
        case 'active':
          label = activeLabel.value
          break
        case 'inactive':
          label = inactiveLabel.value
          break
        default:
          break
      }
      return label
    }

    const searchByLabel = computed(
      () => i18n.t('propertyListSearchByLabel') as string
    )

    const searchByPlaceholder = computed(
      () => i18n.t('propertyListSearchByPlaceholder') as string
    )

    const { data: rows, isLoading, totalItems, error } = useProperties(model)

    const columns = computed<VueGoodTableColumn<PropertyViewModel>[]>(() => [
      {
        label: i18n.t('id') as string,
        field: 'propertyKey',
      },
      {
        label: i18n.t('mls') as string,
        field: 'mlsPropertyId',
      },
      {
        label: i18n.t('status') as string,
        field: 'isDeleted',
      },
      {
        label: i18n.t('agent') as string,
        field: 'agentViewModel',
      },
      {
        label: i18n.t('title') as string,
        field: 'title',
      },
      {
        label: i18n.t('price') as string,
        field: (row) => {
          const formattedPrice = useCurrency({
            value: row.price,
            currency: row.currencyViewModel,
          }).value
          if (isRental(row)) {
            return row.price ? `${formattedPrice} ${i18n.t('perNight')}` : ''
          }
          return formattedPrice
        },
      },
      {
        label: i18n.t('price2') as string,
        field: (row) => {
          const formattedPrice2 = useCurrency({
            value: row.price2,
            currency: row.currencyViewModel,
          }).value
          if (isRental(row)) {
            return row.price2 ? `${formattedPrice2} ${i18n.t('perMonth')}` : ''
          }
          return row.price2 ? formattedPrice2 : ''
        },
      },
      {
        label: i18n.t('type') as string,
        field: (row) => row.typeViewModel.title,
      },
      {
        label: i18n.t('actions') as string,
        field: 'propertyId',
      },
    ])

    function isRental(property: PropertyViewModel) {
      return (
        property.categoryViewModel.categoryId === PropertyCategoryId.Rentals
      )
    }

    function onPageChange(newParams: VueGoodTablePageChangeEvent) {
      router
        .push({
          query: {
            ...currentRoute.value?.query,
            pageNumber: newParams.currentPage.toString(),
          },
        })
        .catch(() => {})
    }

    function onPerPageChange(newParams: VueGoodTablePerPageChangeEvent) {
      router
        .push({
          query: {
            ...currentRoute.value?.query,
            ...(pageNumber.value > 1 ? { pageNumber: '1' } : undefined),
            rowsPerPage: newParams.currentPerPage.toString(),
          },
        })
        .catch(() => {})
    }

    return {
      model,
      pageNumber,
      internalSearchBy,
      internalStatus,
      statuses,
      getStatusLabel,
      statusLabel,
      internalCategory,
      categories,
      getCategoryLabel,
      categoryLabel,
      internalBoost,
      boosts,
      getBoostLabel,
      boostLabel,
      searchByLabel,
      searchByPlaceholder,
      bathroom,
      bedroom,
      minPrice,
      maxPrice,
      orderBy,
      propertyTypeList,
      propertyAreaList,
      rows,
      columns,
      isLoading,
      totalItems,
      error,
      onPageChange,
      onPerPageChange,
      getPropertyRouteTo,
    }
  },
})
