import { AxiosInstance } from 'axios'
import { api } from '../client'
import { serviceUrl } from '../utils'

const controller = serviceUrl('type')

function createTypeService(client: AxiosInstance) {
  return {
    get(params: TypeModel) {
      return client.get<TypeViewModel[]>(controller.action('get'), {
        params,
      })
    },
  }
}

export const typeService = createTypeService(api.base)

export interface TypeModel {
  languageId?: string
}

export interface TypeViewModel {
  typeId: string
  typeKey: number
  name: string
  title: string
  description: string
}
