import { PropertyModel, propertyService } from '@/services/modules/property'
import { currentLanguage } from '@/utils/language'
import { computed, ComputedRef, isRef } from '@vue/composition-api'
import { AxiosRequestConfig } from 'axios'
import { useServicePaginated } from './servicePaginated'

export function useProperties(
  model: ComputedRef<PropertyModel> | PropertyModel
) {
  const request = computed<PropertyModel>(() => ({
    languageId: currentLanguage.value.guid,
    ...(isRef(model) ? model.value : model),
  }))
  return useServicePaginated(request, (config?: AxiosRequestConfig) =>
    propertyService.get(request.value, config)
  )
}
