

































































































































































































import { useAreas } from '@/composition/areas'
import { useTypes } from '@/composition/types'
import { currentRoute } from '@/router'
import { Location } from 'vue-router'
import { AreaGetViewModel } from '@/services/modules/area'
import { TypeViewModel } from '@/services/modules/type'
import { GuidIdTableType } from '@/services/utils'
import i18n from '@/setup/i18n'
import { propertyOrderByConfig } from '@/utils/property'
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from '@vue/composition-api'

interface OrderByOption {
  label: string
  value: string
}

export default defineComponent({
  name: 'PropertyListFilters',

  props: {
    pageNumber: {
      type: Number,
      default: 1,
    },

    bathroom: {
      type: Number,
      default: 0,
    },

    bedroom: {
      type: Number,
      default: 0,
    },

    minPrice: {
      type: Number,
      default: null,
    },

    maxPrice: {
      type: Number,
      default: null,
    },

    minPrice2: {
      type: Number,
      default: null,
    },

    maxPrice2: {
      type: Number,
      default: null,
    },

    propertyTypeList: {
      type: Array as PropType<GuidIdTableType[]>,
      default: () => [],
    },

    propertyAreaList: {
      type: Array as PropType<GuidIdTableType[]>,
      default: () => [],
    },

    orderBy: {
      type: String,
      default: null,
    },

    category: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const bathroomRef = ref<number | undefined>(props.bathroom)
    const bedroomRef = ref<number | undefined>(props.bedroom)
    const minPriceRef = ref<number | undefined>(props.minPrice)
    const minPrice2Ref = ref<number | undefined>(props.minPrice2)
    const maxPriceRef = ref<number | undefined>(props.maxPrice)
    const maxPrice2Ref = ref<number | undefined>(props.maxPrice2)
    const orderByRef = ref<string | undefined>(props.orderBy)
    const propertyTypeListRef = ref(props.propertyTypeList)
    const propertyAreaListRef = ref(props.propertyAreaList)

    const propertyTypeListArrRef = computed<string[]>(() => {
      if (propertyTypeListRef.value.length > 0) {
        let arr = propertyTypeListRef.value.map((item) => item.id)
        return arr
      } else {
        return []
      }
    })

    const propertyAreaListArrRef = computed<string[]>(() => {
      if (propertyAreaListRef.value.length > 0) {
        let arr = propertyAreaListRef.value.map((item) => item.id)
        return arr
      } else {
        return []
      }
    })

    const queryRef = computed(() => ({
      bathroom:
        bathroomRef.value !== undefined && bathroomRef.value > 0
          ? bathroomRef.value?.toString()
          : undefined,
      bedroom:
        bedroomRef.value !== undefined && bedroomRef.value > 0
          ? bedroomRef.value?.toString()
          : undefined,
      minPrice: minPriceRef.value?.toString(),
      minPrice2: minPrice2Ref.value?.toString(),
      maxPrice: maxPriceRef.value?.toString(),
      maxPrice2: maxPrice2Ref.value?.toString(),
      orderBy: orderByRef.value?.toString(),
      propertyTypeList: propertyTypeListArrRef.value,
      propertyAreaList: propertyAreaListArrRef.value,
    }))

    const {
      data: propertyTypeListOptions,
      isLoading: isLoadingPropertyTypeListOptions,
    } = useTypes()

    const propertyTypeListIds = computed(() =>
      propertyTypeListRef.value.map((item) => item.id)
    )

    const propertyTypeListValue = computed(() => {
      let list: TypeViewModel[] = []

      for (const item of propertyTypeListOptions.value) {
        if (!propertyTypeListIds.value.includes(item.typeId)) continue
        list.push(item)
      }

      return list
    })

    const propertyTypeListPlaceholder = computed(
      () => i18n.t('propertyTypeListPlaceholder') as string
    )

    function onPropertyTypeListChange(newValue: TypeViewModel[]) {
      if (newValue.length > 0) {
        let arr = newValue.map((item) => item.typeId)
        let list = []
        for (const item of arr) {
          if (!item) continue
          list.push({ id: item })
        }
        propertyTypeListRef.value = list
      } else {
        propertyTypeListRef.value = []
      }
    }

    const {
      data: propertyAreaListOptions,
      isLoading: isLoadingPropertyAreaListOptions,
    } = useAreas()

    const propertyAreaListIds = computed(() =>
      propertyAreaListRef.value.map((item) => item.id)
    )

    const propertyAreaListValue = computed(() => {
      let list: AreaGetViewModel[] = []

      for (const item of propertyAreaListOptions.value) {
        if (!propertyAreaListIds.value.includes(item.areaId)) continue
        list.push(item)
      }

      return list
    })

    const propertyAreaListPlaceholder = computed(
      () => i18n.t('propertyAreaListPlaceholder') as string
    )

    const isRentals = computed(() =>
      Boolean(props.category && props.category === 'rentals')
    )

    const minPricePlaceholder = computed(
      () => i18n.t(isRentals.value ? 'minPricePerNight' : 'minPrice') as string
    )

    const maxPricePlaceholder = computed(
      () => i18n.t(isRentals.value ? 'maxPricePerNight' : 'maxPrice') as string
    )

    const minPrice2Placeholder = computed(
      () => i18n.t('minPricePerMonth') as string
    )

    const maxPrice2Placeholder = computed(
      () => i18n.t('maxPricePerMonth') as string
    )

    const orderByPlaceholder = computed(() => i18n.t('orderBy') as string)

    const orderByOptions = computed<OrderByOption[]>(() => {
      let options: OrderByOption[] = []

      for (const [key, item] of Object.entries(propertyOrderByConfig)) {
        if (props.category && !item.categories.includes(props.category))
          continue
        options.push({
          label: i18n.t(`orderByOptions.${key}`) as string,
          value: item.value,
        })
      }
      return options
    })

    const orderByValue = computed(() =>
      orderByOptions.value.find((option) => option.value === orderByRef.value)
    )

    function onOrderByChange(newValue: OrderByOption | null) {
      orderByRef.value = (newValue && newValue.value) || undefined
    }

    function onPropertyAreaListChange(newValue: AreaGetViewModel[]) {
      if (newValue.length > 0) {
        let arr = newValue.map((item) => item.areaId)
        let list = []
        for (const item of arr) {
          if (!item) continue
          list.push({ id: item })
        }
        propertyAreaListRef.value = list
      } else {
        propertyAreaListRef.value = []
      }
    }

    function onBathroomDecrement() {
      let value = bathroomRef.value ? bathroomRef.value - 1 : null
      onBathroomChange(value)
    }

    function onBathroomIncrement() {
      let value = bathroomRef.value ? bathroomRef.value + 1 : 1
      onBathroomChange(value)
    }

    function onBathroomChange(newValue: number | null) {
      bathroomRef.value = newValue ? newValue : undefined
    }

    function onBedroomDecrement() {
      let value = bedroomRef.value ? bedroomRef.value - 1 : null
      onBedroomChange(value)
    }

    function onBedroomIncrement() {
      let value = bedroomRef.value ? bedroomRef.value + 1 : 1
      onBedroomChange(value)
    }

    function onBedroomChange(newValue: number | null) {
      bedroomRef.value = newValue ? newValue : undefined
    }

    function onMinPriceChange(newValue: string): void {
      let minPrice = Number.parseFloat(newValue)

      if (props.maxPrice && minPrice > props.maxPrice) {
        return
      }

      minPriceRef.value = minPrice ? minPrice : undefined
    }

    function onMinPrice2Change(newValue: string): void {
      let minPrice2 = Number.parseFloat(newValue)

      if (props.maxPrice2 && minPrice2 > props.maxPrice2) {
        return
      }

      minPrice2Ref.value = minPrice2 ? minPrice2 : undefined
    }

    function onMaxPriceChange(newValue: string): void {
      let maxPrice = Number.parseFloat(newValue)

      if (props.minPrice && props.minPrice > maxPrice) {
        return
      }

      maxPriceRef.value = maxPrice ? maxPrice : undefined
    }

    function onMaxPrice2Change(newValue: string): void {
      let maxPrice2 = Number.parseFloat(newValue)

      if (props.minPrice2 && props.minPrice2 > maxPrice2) {
        return
      }

      maxPrice2Ref.value = maxPrice2 ? maxPrice2 : undefined
    }

    const applyFilters = computed<Location>(() => ({
      query: {
        ...currentRoute.value?.query,
        ...queryRef.value,
        ...(props.pageNumber > 1 ? { pageNumber: '1' } : undefined),
      },
    }))

    const resetFiltersExceptSearchByRouteTo = computed<Location>(() => ({
      query: {
        searchBy:
          currentRoute.value &&
          currentRoute.value.query.searchBy &&
          typeof currentRoute.value.query.searchBy === 'string'
            ? currentRoute.value.query.searchBy
            : undefined,
      },
    }))

    watch(
      () => [
        props.bathroom,
        props.bedroom,
        props.minPrice,
        props.minPrice2,
        props.maxPrice,
        props.maxPrice2,
      ],
      ([
        newBathroom,
        newBedroom,
        newMinPrice,
        newMinPrice2,
        newMaxPrice,
        newMaxPrice2,
      ]) => {
        bathroomRef.value = newBathroom
        bedroomRef.value = newBedroom
        minPriceRef.value = newMinPrice
        minPrice2Ref.value = newMinPrice2
        maxPriceRef.value = newMaxPrice
        maxPrice2Ref.value = newMaxPrice2
        maxPrice2Ref.value = newMaxPrice2
        maxPrice2Ref.value = newMaxPrice2
      }
    )

    watch(
      () => props.orderBy,
      (newOrderBy) => (orderByRef.value = newOrderBy)
    )

    watch(
      () => [props.propertyTypeList, props.propertyAreaList],
      ([newPropTypeList, newPropAreaList]) => {
        propertyTypeListRef.value = newPropTypeList
        propertyAreaListRef.value = newPropAreaList
      }
    )

    return {
      propertyTypeListValue,
      propertyTypeListPlaceholder,
      propertyTypeListOptions,
      isLoadingPropertyTypeListOptions,
      onPropertyTypeListChange,
      propertyAreaListValue,
      propertyAreaListPlaceholder,
      propertyAreaListOptions,
      isLoadingPropertyAreaListOptions,
      onPropertyAreaListChange,
      isRentals,
      orderByValue,
      orderByPlaceholder,
      orderByOptions,
      onOrderByChange,
      minPricePlaceholder,
      maxPricePlaceholder,
      minPrice2Placeholder,
      maxPrice2Placeholder,
      onMinPriceChange,
      onMinPrice2Change,
      onMaxPriceChange,
      onMaxPrice2Change,
      onBathroomDecrement,
      onBathroomIncrement,
      onBedroomDecrement,
      onBedroomIncrement,
      applyFilters,
      resetFiltersExceptSearchByRouteTo,
      bathroomRef,
      bedroomRef,
      minPriceRef,
      minPrice2Ref,
      maxPriceRef,
      maxPrice2Ref,
    }
  },
})
