import { AxiosInstance } from 'axios'
import { api } from '../client'
import { Audit, serviceUrl } from '../utils'

const controller = serviceUrl('exchange')

function createExchangeRateService(client: AxiosInstance) {
  return {
    get(params: ExchangeModel) {
      return client.get<ExchangeViewModel[]>(controller.action('get'), {
        params,
      })
    },

    update(model: ExchangeUpdateModel) {
      return client.post<boolean>(controller.action('update'), model)
    },
  }
}

export const exchangeRateService = createExchangeRateService(api.base)

export interface ExchangeModel {
  exchangeKey?: number
}

export interface ExchangeUpdateModel {
  exchangeId: string
  amount: number
}

export interface ExchangeViewModel extends Audit {
  exchangeId: string
  exchangeKey: number
  name: string
  abbreviation: string
  amount: number
  isDeleted: boolean
}

export const PESOS_EXCHANGE_KEY = 1
