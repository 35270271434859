import { TypeModel, typeService } from '@/services/modules/type'
import { currentLanguage } from '@/utils/language'
import { computed, ComputedRef, isRef } from '@vue/composition-api'
import { useService } from './service'

export function useTypes(model?: ComputedRef<TypeModel> | TypeModel) {
  const request = computed<TypeModel>(() => ({
    languageId: currentLanguage.value.guid,
    ...(isRef(model) ? model.value : model),
  }))
  return useService(request, () => typeService.get(request.value))
}
