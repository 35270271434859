import { AxiosInstance } from 'axios'
import { api } from '../client'
import { serviceUrl } from '../utils'

const controller = serviceUrl('area')

function createAreaService(client: AxiosInstance) {
  return {
    get(params: AreaModel) {
      return client.get<AreaGetViewModel[]>(controller.action('get'), {
        params,
      })
    },
  }
}

export const areaService = createAreaService(api.base)

export interface AreaModel {
  languageId?: string
}

export interface AreaViewModel {
  areaId: string
  areaKey: number
  name: string
}

export interface AreaGetViewModel {
  areaId: string
  areaKey: number
  areaName: string
  areaDescription: string
  cityId: string
  cityKey: number
  cityName: string
  stateId: string
  stateKey: number
  stateName: string
  countryId: string
  countryKey: number
  countryName: string
}
